import { useCallback, useContext, useMemo, useState } from 'react';
import useApiCall from 'hooks/use-api-call';
import { InvestorsApi } from 'api/apis';
import makeLink from 'helper/make-link';
import { PersonType } from 'ui/types/person';
import { CreateAccountValues } from 'libraries/wizard/components/account-setup/create-account';
import { I18NContext } from 'ui/i18n/provider';
import WizardContext from 'libraries/wizard/wizard-context';
import useInvestorMe from 'hooks/use-investor-me';
import { AnyServerError } from 'hooks/use-api-error';
import { useServerConfigSelector } from 'core/config/hooks';
import { Config, TokenContractInfo } from 'api';
import useRegister from 'subapps/investment/pages/register/hooks/use-register';
import { PreSelectionMode } from 'libraries/wizard/components/account-setup';
import { setRegistrationLocalStorage } from 'subapps/investment/helpers';
import { AUTH_ROUTES } from 'core/auth/pages/routes.config';
import applicationConfig from 'core/config/local';

const useRegisterAccountCreation = (): {
  setPreSelectionMode: (mode: PreSelectionMode) => void;
  onSubmit: (values: CreateAccountValues) => void;
  preSelectionMode?: string;
  continueProcessURL: string;
  createAccountMode: boolean;
  email?: string;
  isLoggedIn: boolean;
  config: Config;
  resourceError?: AnyServerError;
  apiError?: AnyServerError;
  apiLoading: boolean;
  showLoading: boolean;
  isTransferInvitation: boolean;
  tokenizationInfo?: TokenContractInfo | null;
  allowedPersonTypes: PersonType[];
} => {
  //contexts
  const { resourceId: investorId, finalize, loading, nextStep } = useContext(WizardContext);
  const { activeLocale } = useContext(I18NContext);

  //state
  const [loginEmail, setLoginEmail] = useState<string>();

  //hooks
  const { error: apiError, loading: apiLoading, makeAuthenticatedApi, withApi } = useApiCall();
  const { error: registerError } = useRegister();
  const { isLoggedIn, loading: meLoading } = useInvestorMe();
  const { config, loading: configLoading } = useServerConfigSelector();

  //apis
  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  //variables
  const createAccountMode = !isLoggedIn;
  const showLoading = meLoading || configLoading || loading;
  const loginURL = makeLink(AUTH_ROUTES.login, {}, true);
  const allowedPersonTypes = [PersonType.Natural, PersonType.Legal];

  //functions
  const onSubmit = useCallback(
    (values: CreateAccountValues) => {
      withApi(async () => {
        if (!values.naturalPerson)
          // TODO(niklasb) dropping errors silently is bad!
          return null; // todo: potential

        const naturalPerson = {
          ...values.naturalPerson,
          birthDate: new Date(values.naturalPerson.birthDate),
          country: values.naturalPerson.country as any, // TODO(geforcefan): write api converter instead of using any
          salutation: values.naturalPerson.salutation,
          // for legal persons: reset natural person address fields for data to submit
          // user might have filled these fields before selecting the "legal" option
          ...(values.personType === PersonType.Legal
            ? {
                street: undefined,
                zip: undefined,
                city: undefined,
                country: undefined,
              }
            : {}),
        };

        const commonCreateAccountValues = values.accountCredentials &&
          values.acceptDocuments && {
            password: values.accountCredentials.password,
            preferredLanguage: activeLocale,
            // TODO(niklasb) this field is misnamed
            acceptEffecta:
              values.acceptDocuments.effectaAccountSetupDocument || values.acceptDocuments.accountSetupDocument,
            acceptTos: values.acceptDocuments.effectaTermsConditions || values.acceptDocuments.termsOfService,
            communicationSettings: {
              optinEmail: values.communicationSettings?.optinEmail,
              optinMail: values.communicationSettings?.optinMail,
              optinPhone: values.communicationSettings?.optinPhone,
            },
          };

        const legalPerson =
          values.personType === PersonType.Legal && values.legalPerson
            ? {
                ...values.legalPerson,
                country: values.legalPerson.country as any, // TODO(mara-cashlink): write api converter instead of using any
              }
            : undefined;

        if (
          !values.accountCredentials ||
          !commonCreateAccountValues ||
          !naturalPerson.forename ||
          !naturalPerson.surname
        )
          // TODO(niklasb) dropping errors silently is bad!
          return null;

        if (createAccountMode) {
          setLoginEmail(values.accountCredentials.email);

          const { access } = await investorsApi.investorsRegistrationCreate({
            registerOnlyInvestorCreationRequest: {
              distributionPlatformName: applicationConfig.platform,
              naturalPerson: {
                ...naturalPerson,
                // TODO(niklasb) why is this required?
                forename: naturalPerson.forename,
                surname: naturalPerson.surname,
                email: values.accountCredentials.email,
              },
              legalPerson,
              ...commonCreateAccountValues,
            },
          });
          setRegistrationLocalStorage(true);
          finalize(access);
          nextStep();
        } else {
          if (!naturalPerson.forename || !naturalPerson.surname)
            // TODO(niklasb) dropping errors silently is bad!
            return;
          await investorsApi.investorsPartialUpdate({
            id: investorId,
            patchedInvestorUpdateRequest: {
              naturalPerson: {
                ...naturalPerson,
              },
              legalPerson,
            },
          });
          nextStep();
        }
      });
    },
    [withApi, investorsApi, activeLocale, finalize, nextStep],
  );

  return useMemo(
    () => ({
      onSubmit,
      continueProcessURL: loginURL,
      createAccountMode,
      isLoggedIn,
      showLoading,
      config,
      resourceError: registerError,
      apiError,
      apiLoading,
      allowedPersonTypes,
      isTransferInvitation: false,
      preSelectionMode: undefined,
      setPreSelectionMode: () => {},
    }),
    [
      onSubmit,
      loginURL,
      createAccountMode,
      loginEmail,
      isLoggedIn,
      showLoading,
      config,
      registerError,
      apiError,
      apiLoading,
    ],
  );
};

export default useRegisterAccountCreation;
